import { makeStringClear } from './helpers'

const CLIENT_COOKIE_NAME = 'client-session-bind'
const CLIENT_HEADER = 'client-binding'

const deniedCookieNames = new Set(
	[
		CLIENT_COOKIE_NAME,
		CLIENT_HEADER, // adding this for precaution when hardening cookies
		'svSession',
		'smSession',
		'server-session-bind',
		'wixSession2',
	].map((cookieName) => cookieName.toLowerCase())
)

const cookieDescriptor = Object.getOwnPropertyDescriptor(Document.prototype, 'cookie')

// Returns a cookie string that expires the client session cookie
const buildExpiredSessionCookie = (domain: string = '') =>
	`${CLIENT_COOKIE_NAME}=; ${domain} max-age=0; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT`

const removeSessionCookie = () => {
	const domain = `domain=${location.hostname};`
	const cookieParamsNoDomain = buildExpiredSessionCookie()
	const cookieParams = buildExpiredSessionCookie(domain)
	// This removes cookies set after we made the switch to the new model of writing cookies without domain (for Fastly)
	cookieDescriptor!.set!.call(document, cookieParamsNoDomain)
	// This removes cookies set with domain
	cookieDescriptor!.set!.call(document, cookieParams)
}

const isDeniedCookie = (cookie: string | { name: string }) => {
	const rawCookie = typeof cookie === 'string' ? cookie.split('=')[0].trim() : cookie.name
	const cookieName = makeStringClear(rawCookie).toLowerCase()

	return deniedCookieNames.has(cookieName)
}

const filterCookies = (cookies: string | Array<{ name: string }>): Array<string | { name: string }> => {
	let normalizedCookies: Array<string | { name: string }>
	if (typeof cookies === 'string') {
		normalizedCookies = cookies.split(';').map((_) => _.trim())
	} else {
		normalizedCookies = cookies
	}

	return normalizedCookies.filter((cookie: string | { name: string }) => !isDeniedCookie(cookie))
}

export const getAndDeleteClientSessionValue = () => {
	const clientSessionValue = document.cookie
		.split(';')
		.map((_) => _.trim())
		.filter((cookieName) => cookieName.startsWith(CLIENT_COOKIE_NAME))[0]
		?.split('=')[1]

	removeSessionCookie()

	return clientSessionValue
}

export const guardCookie = () => {
	Object.defineProperty(document, 'cookie', {
		get() {
			const cookies = cookieDescriptor!.get!.call(document)
			const finalValues = filterCookies(cookies)
			return finalValues.join('; ')
		},
		set(value: string) {
			const testValue = makeStringClear(value.split(';')[0])
			const shouldWrite = [...deniedCookieNames].every(
				(deniedCookieName) => !testValue.startsWith(deniedCookieName.toLowerCase())
			)
			if (shouldWrite) {
				cookieDescriptor!.set!.call(document, value)
			}
		},
		enumerable: true,
		configurable: false,
	})
}

export const guardCookieStore = () => {
	// @ts-expect-error
	if (!globalThis.cookieStore) {
		return
	}

	// @ts-expect-error
	const originalGet = globalThis.cookieStore.get.bind(globalThis.cookieStore)
	// @ts-expect-error
	const originalGetAll = globalThis.cookieStore.getAll.bind(globalThis.cookieStore)
	// @ts-expect-error
	const originalSet = globalThis.cookieStore.set.bind(globalThis.cookieStore)
	// @ts-expect-error
	const originalDelete = globalThis.cookieStore.delete.bind(globalThis.cookieStore)

	// @ts-expect-error
	Object.defineProperty(globalThis.CookieStore.prototype, 'get', {
		value: async (name: string) => {
			if (deniedCookieNames.has(name.toLowerCase())) {
				return null
			}
			return originalGet.call(this, name)
		},
		enumerable: true,
		configurable: false,
	})

	// @ts-expect-error
	Object.defineProperty(globalThis.CookieStore.prototype, 'getAll', {
		value: async () => {
			const cookies = await originalGetAll.call(this)
			return filterCookies(cookies)
		},
		enumerable: true,
		configurable: false,
	})

	// @ts-expect-error
	Object.defineProperty(globalThis.CookieStore.prototype, 'set', {
		value: async (...args: Array<any>) => {
			// Handle both set('name', 'value') and set({name, value, ...options})
			const name = args.length === 1 ? args[0].name : args[0]

			if (isDeniedCookie(name)) {
				return
			}

			return originalSet.call(this, ...args)
		},
		enumerable: true,
		configurable: false,
	})

	// @ts-expect-error
	Object.defineProperty(globalThis.CookieStore.prototype, 'delete', {
		value: async (...args: Array<any>) => {
			// Handle both set('name', 'value') and set({name, value, ...options})
			const name = args.length === 1 ? args[0].name : args[0]

			if (isDeniedCookie(name)) {
				return
			}

			return originalDelete.call(this, ...args)
		},
		enumerable: true,
		configurable: false,
	})
}
